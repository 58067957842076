class ViewportObserver {
    constructor(callback) {
        this.callback = callback;
        this.currentViewport = this.getViewportSize();
        this.resizeObserver = new ResizeObserver(() => this.handleResize());
        this.resizeObserver.observe(document.body);
    }

    handleResize() {
        const newViewport = this.getViewportSize();
        if (newViewport !== this.currentViewport) {
            this.currentViewport = newViewport;
            this.callback(this.currentViewport);
        }
    }

    getViewportSize() {
        return window.innerWidth >= 1024 ? 'desktop' : 'small';
    }

    disconnect() {
        this.resizeObserver.disconnect();
    }
}