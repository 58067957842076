class HoehenMesser {
    constructor(container) {
        this.container = document.querySelector(container);
        this.abstandZumOberenRandWert = 0;
    }

    abstandZumOberenRandMessen(paragraphsTag, index) {
        if (this.container) {
            const paragraphs = this.container.getElementsByTagName(paragraphsTag);

            if (paragraphs.length > 1) {
                const secondParagraph = paragraphs[index];
                const rect = secondParagraph.getBoundingClientRect();
                this.abstandZumOberenRandWert = rect.top - this.container.getBoundingClientRect().top;

                console.log('Abstand zum oberen Rand des Containers:', this.abstandZumOberenRandWert);
                return this.abstandZumOberenRandWert;
            } else {
                console.error('Es gibt weniger als zwei p-Elemente.');
                return null;
            }
        } else {
            console.error('Container nicht gefunden.');
            return null;
        }
    }

    get abstandZumOberenRand() {
        return this.abstandZumOberenRandWert;
    }

    abstandZumOberenRandHinzufuegen(bild) {
        const abstand = this.abstandZumOberenRand;
        if (abstand !== 0) {
            const bildElement = document.querySelector(bild);
            if (bildElement) {
                bildElement.style.marginTop = abstand + 'px';
            } else {
                console.error(`Element mit Selector ${bild} nicht gefunden.`);
            }
        } else {
            console.error('Abstandswert ist null oder nicht gesetzt.');
        }
    }
}