gsap.registerPlugin(ScrollTrigger);

class HorizontalScrollController {
    constructor(containerSelector, menueItemSelector, sectionSelector, pinScrolltrigger) {
        this.containerSelector = containerSelector;
        this.menueItemSelector = menueItemSelector;
        this.sectionSelector = sectionSelector;
        this.pinScrolltrigger = pinScrolltrigger;

        this.pinWrap = document.querySelector(this.containerSelector);
        this.scrollingContainer = this.pinWrap;

        this.scrollingContainer = this.pinWrap;
        this.menueItems = Array.from(document.querySelectorAll(this.menueItemSelector));
        this.sections = gsap.utils.toArray(this.sectionSelector);
        this.sectionsMap = new Map();
        this.itemsMap = new Map();

        this.setupSections();
        this.setupMenueClick();
    }

    initHorizontalScroll() {
        if (this.pinWrap != null) {
            this.setupHorizontalScroll();
            window.addEventListener("resize", () => {
                this.refresh();
            });
        }
    }

    // vermeidet Lücken bei der Grössenänderung
    refresh() {
        this.horizontalScrollLength = this.pinWrap.offsetWidth - window.innerWidth;
        ScrollTrigger.refresh();
    }

    setupHorizontalScroll() {
        this.horizontalScrollLength = this.pinWrap.offsetWidth - window.innerWidth;

        this.horizontalScrollTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: this.containerSelector,
                start: "top top",
                end: () => `+=${this.horizontalScrollLength}`,
                pin: this.pinScrolltrigger,
                invalidateOnRefresh: true,
                scrub: true,
                pinSpacing: true,
                onUpdate: self => {
                    const activeMenueItemSelector = self.progress === 1 ? `${this.menueItemSelector}--entstehung` : `${this.menueItemSelector}--konzept`;
                    const menueItemElement = document.querySelector(activeMenueItemSelector);
                    if (menueItemElement) {
                        this.setActiveMenueItem(menueItemElement);
                    }
                }
            }
        });

        this.horizontalScrollTimeline.to(this.scrollingContainer, {
            x: () => -this.horizontalScrollLength,
            ease: 'none',
        });
    }

    setupSections() {
        this.sections.forEach((section, index) => {
            this.sectionsMap.set(section, this.menueItems[index]);
            this.itemsMap.set(this.menueItems[index], section);
        });

        this.sections.forEach((section, i) => {
            ScrollTrigger.create({
                trigger: section,
                start: "top center",
                end: "Top center",
                onEnter: () => {
                    if (!section.matches(this.sectionSelector)) return;
                    this.setActiveMenueItem(this.sectionsMap.get(section));
                },
                onLeaveBack: () => {
                    if (!section.matches(this.sectionSelector)) return;
                    this.setActiveMenueItem(this.sectionsMap.get(this.sections[i - 1]));
                }
            });
        });
    }

    setupMenueClick() {
        const header = document.querySelector(".header__element--projekt");
        this.menueItems.forEach((item) => {
            item.addEventListener("click", () => {
                const targetSection = this.itemsMap.get(item);
                if (targetSection) {
                    this.scrollToSection(targetSection);
                } else {
                    console.error(`Section nicht gefunden: ${item}`);
                }
            });
        });
    }

    setActiveMenueItem(element) {
        this.menueItems.forEach((item) => {
            if (element === item) {
                item.setAttribute("data-menue-projekt", "aktiv");
            } else {
                item.removeAttribute("data-menue-projekt");
            }
        });
    }

    scrollToSection(section) {
        let projektProjekteBau = document.querySelector('.projekt__projekt--bau');
        let paddingTopProjektProjekteBau = window.getComputedStyle(projektProjekteBau).paddingTop;
        let paddingTopValueBau = parseFloat(paddingTopProjektProjekteBau);
        console.log(paddingTopValueBau);

        const hasKonzept = document.querySelector("#konzept");
        const hasEntstehung = document.querySelector("#entstehung");

        let offsetTop = 0;

        if (section.matches('#entstehung')) {
            if (hasKonzept && hasEntstehung) {
                // Wenn sowohl "#konzept" als auch "#entstehung" vorhanden sind
                offsetTop = hasEntstehung.offsetTop + this.horizontalScrollLength;
                console.log("Scrollen zu Entstehung, mit Konzept");
            } else if (hasEntstehung) {
                // Wenn nur "#entstehung" vorhanden ist
                offsetTop = hasEntstehung.offsetTop;
                console.log("Scrollen zu Entstehung");
            }
        } else if (section.matches('#konzept')) {
            if (hasKonzept) {
                // Wenn nur "#konzept" vorhanden ist
                offsetTop = hasKonzept.offsetTop + paddingTopValueBau;
            }
        }

        window.scrollTo({
            top: offsetTop,
            behavior: "smooth"
        });
    }


}