class ScrollerMitGrain {

    constructor(main, body, text) {
        this.main = document.querySelector(main); 
        this.body = document.querySelector(body);
        this.textSelector = text;
        this.texte = document.querySelectorAll(this.textSelector);

        if (!this.main || !this.body || this.texte.length === 0) {
            console.error("Eines oder mehrere der erforderlichen Elemente wurden nicht gefunden.");
            return;
        }

        this.updateDimensions();
    }

    updateDimensions() {
        const ersterText = document.querySelector(`${this.textSelector}:first-child`);
        const letzterText = document.querySelector(`${this.textSelector}:last-child`);
        
        if (!ersterText || !letzterText || !this.body) {
            console.error("Ein oder mehrere erforderliche Elemente für die Dimensionsberechnung konnten nicht gefunden werden.");
            return;
        }

        this.ersterTextHeight = ersterText.offsetHeight;
        this.letzterText = letzterText;
        this.bodyHeight = this.body.offsetHeight;
        this.mainPaddingTop = parseFloat(getComputedStyle(this.main).paddingTop);
    }

    init() {
        if (!this.main || !this.body || this.texte.length === 0) return;

        this.texte = Array.from(this.texte);
        // Entferne das erste Element
        this.texte.shift();
        this.texte.forEach(text => {
            this.initializeScrollTrigger(text);
        });

        this.addEventListeners();
        this.haltungScrollbarMachen();
    }

    haltungScrollbarMachen = debounce(() => {
        this.updateDimensions();
        let haltungTexteHeight = 0;

        this.texte.forEach(text => {
            haltungTexteHeight += text.offsetHeight;
        });

        let windowHeight = window.innerHeight;
        let haltungTexteContainer = this.main;

        if (!haltungTexteContainer) {
            console.error("Das Hauptcontainer-Element konnte nicht gefunden werden.");
            return;
        }

        // Höhe des letzten Textes ohne Padding-Top berechnen
        const letzterHaltungTextHeight = this.letzterText ?
            this.letzterText.offsetHeight - parseFloat(getComputedStyle(this.letzterText).paddingTop)
            : 0;

        console.log("Padding top: ", this.mainPaddingTop);

        let marginBottom;
        if (this.bodyHeight - this.mainPaddingTop <= windowHeight) {
            marginBottom = haltungTexteHeight + this.ersterTextHeight - letzterHaltungTextHeight;
        } else {
            marginBottom = windowHeight - letzterHaltungTextHeight - this.mainPaddingTop;
        }

        console.log(haltungTexteHeight + this.ersterTextHeight);

        haltungTexteContainer.style.marginBottom = `${marginBottom}px`;
    }, 200);

    initializeScrollTrigger(text) {
        const refresh = () => {
            this.haltungPadding = gsap.getProperty(text, 'paddingTop');
            this.mainPaddingTop = gsap.getProperty(this.main, 'paddingTop');
        };

        gsap.from(text, {
            filter: 'blur(2px)',
            scrollTrigger: {
                trigger: text,
                start: () => {
                    console.log("Start Callback - this.haltungPadding:", this.haltungPadding, "this.text.offsetHeight:", text.offsetHeight, "mainPaddingTop: " + this.mainPaddingTop);
                    return `top+=${this.haltungPadding} top+=${text.offsetHeight + this.mainPaddingTop}`;
                },
                scrub: true,
                end: () => `top+=${this.haltungPadding} top+=${this.mainPaddingTop}`
            }
        });

        ScrollTrigger.addEventListener("refreshInit", refresh);
    }

    addEventListeners() {
        window.addEventListener('resize', this.haltungScrollbarMachen);
    }
}